import React from 'react';
import bp1377 from '../bp1377.jpeg';
import { Image } from 'semantic-ui-react';

const Picture = () => {
  return (
    <Image bordered centered circular size="small" alt="" src={bp1377} />
  );
};
export default Picture;
